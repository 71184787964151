var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reporting-module"
  }, [_c('sygni-access-modal'), _c('div', {
    staticClass: "reporting-module__container"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v("Reporting forms")]), _c('reporting-form-table', {
    attrs: {
      "tableItems": _vm.reportFormPeriods
    }
  })], 1)])]), _c('reporting-filters', {
    attrs: {
      "editMode": _vm.editMode,
      "report": _vm.report,
      "showLePicker": false,
      "reportCommon": _vm.reportCommon
    }
  }), _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('reporting-table', {
    attrs: {
      "editMode": _vm.editMode,
      "selectedCol": 0,
      "tableFields": _vm.globalHeaderFields,
      "tableSections": _vm.tableSections
    }
  })], 1)]), _c('div', {
    staticClass: "reporting-module__footer"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "reporting-module__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "filled black fixed-width"
  }, [_vm._v("Edit Report")])], 1)])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }